.fromContainer {
  display: flex;
  justify-content: center;
  height: var(--box-height);
  align-items: center;
}

.main {
  background-color: #ffffff;
  width: 400px;
  min-height: 300px;
  border-radius: 1.5em;
  box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
}

.sign {
  color: var(--c1);
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}

.input {
  color: rgb(38, 50, 56);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  background: rgba(136, 126, 126, 0.04);
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  outline: none;
  box-sizing: border-box;
  border: 2px solid rgba(0, 0, 0, 0.02);
  text-align: center;
}

form.form1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 250px;
}

.un:focus {
  border: 2px solid rgba(0, 0, 0, 0.18) !important;
}

.submit {
  cursor: pointer;
  border-radius: 5em;
  color: #fff;
  background: linear-gradient(to right, var(--c1), var(--c2));
  border: 0;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-family: "Ubuntu", sans-serif;
  font-size: 13px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
}

.forgot {
  text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
  color: #e1bee7;
  padding-top: 15px;
}

a {
  text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
  color: #e1bee7;
  text-decoration: none;
}

.devNotice {
  text-align: center;
  display: block;
  font-size: 0.8rem;
}

.fileLabel {
  cursor: pointer;
  width: 60%;
}
