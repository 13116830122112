.box {
  padding: 1rem;
  /* this calc gives us the space left between header and footer + some padding | 3.3rem = 2rem(padding) + 1.3rem(footer) */
  height: var(--box-height);
  overflow-y: auto;
  overflow-x: visible;
}

.box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.box::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: #f5f5f5;
}

.box::-webkit-scrollbar-thumb {
  background-color: #000000;
}
