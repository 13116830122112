:root {
  --c1: #52489c;
  --c2: #4062bb;
  --c2-dark: #345098;
  --c3: #59c3c3;
  --c4: #ebebeb;
  --c4-dark: #cccccc;
  --c4-darker: #5c5c5c;
  --c5: #f45b69;
  --c5-dark: #f02d3d;
  --c5-darker: #9b0819;

  --box-height: calc(calc(100vh - 67px) - 3.3rem);
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main {
  flex: 1;
}

.footer {
  background-color: var(--c4-dark);
  text-align: center;
  flex: 0 0 1.3rem;
  font-size: 0.9rem;
  overflow: hidden;
}
