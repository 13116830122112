.navbarContainer {
  overflow-x: scroll;
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: var(--c5);
  width: 100%;
  height: 65px;
  min-width: 1080px;
}

.navbar_left > img {
  margin-left: 1.5rem;
  margin-top: 0.3rem;
  align-self: center;
  width: 10rem;
}

.navbar_right {
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar_content {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
}

.navlink {
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: var(--c4);
  border-radius: 0.3rem;
  cursor: pointer;
  transition: background-color 0.1s ease-out;
  color: black;
  text-decoration: none;
  border: none;
  font: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

.navlink:hover {
  background-color: var(--c4-dark);
}

.active_navlink {
  background-color: var(--c4-darker);
  cursor: default;
  color: white;
}

.active_navlink:hover {
  background-color: var(--c4-darker);
}

.navbarContainer::-webkit-scrollbar-track {
  background-color: var(--c5);
}

.navbarContainer::-webkit-scrollbar {
  height: 2px;
}

.navbarContainer::-webkit-scrollbar-thumb {
  background-color: var(--c5-darker);
  background-clip: padding-box;
}
