.songsTable {
  border-collapse: collapse;
  width: 100%;
  min-width: 45rem;
}

.songsTable td,
.songsTable th {
  border: 1px solid;
  padding: 0.25rem;
}

.narrowColumn {
  width: 4em;
  text-align: center;
}
